import React, { createContext, useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";

export const NightModeContext = createContext();

export const NightModeProvider = ({ children }) => {
  const [nightMode, setNightMode] = useState(() => {
    const savedMode = Cookies.get("nightMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    Cookies.set("nightMode", JSON.stringify(nightMode), { expires: 7 });
  }, [nightMode]);

  return (
    <NightModeContext.Provider value={{ nightMode, setNightMode }}>
      {children}
    </NightModeContext.Provider>
  );
};

export const useNightMode = () => useContext(NightModeContext);
