import React, { useContext, useEffect, useState } from "react";
import { MdOutlineCake } from "react-icons/md";
import { MdOutlineLocalPhone } from "react-icons/md";
import { TitleContext } from "../context/TitleContext";
import { SidebarContext } from "../context/SidebarContext";
import { UserContext } from "../context/userContext";
import { FaPlus } from "react-icons/fa6";
import { IoIosClose } from "react-icons/io";
import { MdOutlineInsertLink } from "react-icons/md";
import { toast } from "react-toastify";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { CiLogout } from "react-icons/ci";
import { MdOutlineLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { editUser } from "../service/user.service";
import { takePicture } from "../service/onboard.service";
import { AiOutlineLoading } from "react-icons/ai";
import { getUserData } from "../service/user.service";
import Avatar from "../components/Avatar";

const MyProfile = () => {
  const { setTitle } = useContext(TitleContext);
  const { setSidebar } = useContext(SidebarContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [hobby, setHobby] = useState([]);
  const [newHobby, setNewHobby] = useState("");
  const [hobbyShow, setHobbyShow] = useState(true);
  const [editingIndex, setEditingIndex] = useState(null);

  const [socialShow, setSocialShow] = useState(true);
  const [socialLink, setSocialLink] = useState("");
  const [social, setSocial] = useState({
    link: "",
  });

  const [newPassword, setNewPassword] = useState("");
  const [showNew, setShowNew] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [showOld, setShowOld] = useState(false);

  const [repeatPassword, setRepeatPassword] = useState("");
  const [showRepeat, setShowRepeat] = useState(false);

  const [phone, setPhone] = useState(0);

  const [birthday, setBirthday] = useState("");

  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    getUserData()
      .then((data) => {
        setImage(data.data.profileUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setTitle("Миний Profile settings");
  }, [setTitle]);

  useEffect(() => {
    setSidebar("closed");
  }, [setSidebar]);

  if (user === null) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        Loading...
      </div>
    );
  }

  const handleAddHobby = () => {
    if (newHobby.trim() === "") return;

    if (editingIndex !== null) {
      const updatedHobbies = [...hobby];
      updatedHobbies[editingIndex] = newHobby;
      setHobby(updatedHobbies);
      setEditingIndex(null);
    } else {
      setHobby([...hobby, newHobby]);
    }

    setNewHobby("");
  };

  const handleEditHobby = (index) => {
    setNewHobby(hobby[index]);
    setEditingIndex(index);
    const updatedHobbies = hobby.filter((_, i) => i !== index);
    setHobby(updatedHobbies);
  };

  const handleDeleteHobby = (index) => {
    const updatedHobbies = hobby.filter((_, i) => i !== index);
    setHobby(updatedHobbies);
  };

  const handleKeyDownHobby = (e) => {
    if (e.key === "Enter") {
      handleAddHobby();
    }
  };

  const handleKeyDownSocial = (e) => {
    if (e.key === "Enter") {
      handleSaveSocial();
    }
  };

  const handleSaveSocial = () => {
    const isValidUrl = (string) => {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    };

    if (!isValidUrl(socialLink)) {
      toast.error("Invalid URL! Please enter a valid link.");
      return;
    }

    const socialData = {
      link: socialLink,
    };

    setSocialLink("");
    setSocial(socialData);
    setSocialShow(!socialShow);
  };

  const handleSave = async () => {
    let bday = birthday || null;
    let number = null;
    let newPass = newPassword || null;
    let old = oldPassword || null;
    let repeat = repeatPassword || null;
    let url = social.link || null;
    let photo = image || null;

    if (bday !== null) {
      bday = `${birthday} 00:00:00`;
    }

    if (phone) {
      if (phone.length !== 8) {
        toast.error("Утасны дугаараа зөв бичнэ үү");
        return;
      }
      number = phone;
    }

    if (newPass && old && repeat) {
      if (newPass.length < 4) {
        toast.error("Нууц үг 4-с дээш тэмдэгттэй байх ёстой");
        return;
      }
      if (newPass !== repeat) {
        toast.error("Шинэ нууц үг давтан нууц үгтэй таарахгүй байна");
        return;
      }
    }

    try {
      const data = {
        phone: number,
        birthDate: bday,
        newPassword: newPass,
        oldPassword: old,
        socialUrl: url,
        photoUrl: photo,
      };

      await editUser(data);
      console.log(data);
      toast.success("Амжилттай өөрчиллөө");
    } catch (err) {
      console.log("err", err);
      toast.error("Хуучин нууц үг буруу байна");
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const maxSize = 25 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        setError("File size exceeds 25MB limit.");
        setImage(null);
      } else {
        setLoadingImage(true);
        setError(null);

        try {
          const data = await takePicture(file);
          setImage(data.data);
        } catch (err) {
          setError("Failed to upload image.");
        }
        setLoadingImage(false);
      }
    }
  };

  return (
    <div className="w-11/12 sm:w-11/12 md:w-10/12 m-auto pt-6 sm:pt-6 md:pt-11">
      <div className="hidden sm:hidden md:block">
        <div className="flex items-start gap-10 w-full mb-5">
          <div className="w-[55%] px-6 py-3 bg-white shadow-custom rounded-xl">
            <p className="text-[#293951] font-semibold mb-6">Нэмэлт мэдээлэл</p>
            <div>
              <div className="flex items-center gap-6 mb-4">
                <div>
                  <label className="block text-sm text-[#293951] font-semibold mb-1">
                    Төрсөн өдөр
                  </label>
                  <div className="relative">
                    <MdOutlineCake className="text-[#293951] text-xl absolute top-1.5 left-1" />

                    <input
                      onChange={(e) => {
                        setBirthday(e.target.value);
                      }}
                      value={birthday}
                      className="rounded-md text-[#293951] text-sm border border-[#CECFD3] ps-6 pe-2 py-1 w-[160px]"
                      type="date"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm text-[#293951] font-semibold mb-1">
                    Утасны дугаар
                  </label>
                  <div className="relative">
                    <MdOutlineLocalPhone className="text-[#293951] text-xl absolute top-1.5 left-1" />

                    <input
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      value={phone !== 0 ? phone : ""}
                      placeholder="9999-9999"
                      className="rounded-md text-[#293951] text-sm border border-[#CECFD3] ps-6 pe-2 py-1 w-[160px]"
                      type="number"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="w-full border rounded-xl px-4 py-2 mb-4">
              <div className="flex items-center justify-between">
                <p className="text-[#293951]">Сонирхол/Хобби</p>
                <button
                  onClick={() => {
                    setHobbyShow(!hobbyShow);
                  }}
                  className=" text-[#293951]"
                >
                  {hobbyShow ? (
                    <IoIosClose className="text-3xl" />
                  ) : (
                    <FaPlus className="text-xl" />
                  )}
                </button>
              </div>
              {hobbyShow && (
                <div>
                  <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                  <div className="flex items-center justify-between gap-2">
                    <input
                      className="w-full py-2 px-4 border rounded-md text-xs"
                      type="text"
                      value={newHobby}
                      onChange={(e) => setNewHobby(e.target.value)}
                      onKeyDown={handleKeyDownHobby}
                    />
                    <button
                      className="text-2xl text-[#293951]"
                      onClick={handleAddHobby}
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>
              )}
              {!hobbyShow && <hr className="bg-[#F3F4F6] mb-2 mt-2" />}
              {hobby.length !== 0 && (
                <div className="flex items-center gap-2 mt-1 flex-wrap">
                  {hobby.map((items, index) => (
                    <div
                      key={index}
                      className="border border-[#324D72] px-1 py-0.5 flex items-center rounded-lg"
                    >
                      <p
                        className="text-[#324d72] text-xs cursor-pointer"
                        onClick={() => handleEditHobby(index)}
                      >
                        {items}
                      </p>
                      <button
                        className="text-base text-[#324D72]"
                        onClick={() => handleDeleteHobby(index)}
                      >
                        <IoIosClose />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {hobby.length !== 0 && hobbyShow && (
                <div className="flex justify-end mt-2">
                  <button
                    onClick={() => {
                      setHobbyShow(false);
                    }}
                    className="px-5 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-sm"
                  >
                    Хадгалах
                  </button>
                </div>
              )}
            </div> */}
              <div className="w-full border rounded-xl px-4 py-2">
                <div className="flex items-center justify-between">
                  <p className="text-[#293951]">Social хаяг</p>
                  <button
                    onClick={() => {
                      setSocialShow(!socialShow);
                    }}
                    className=" text-[#293951]"
                  >
                    {socialShow ? (
                      <IoIosClose className="text-3xl" />
                    ) : (
                      <FaPlus className="text-xl" />
                    )}
                  </button>
                </div>
                {!socialShow && (
                  <div>
                    <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <MdOutlineInsertLink className="text-[#293951] text-2xl" />
                        {social.link && (
                          <a
                            rel="noreferrer"
                            className="underline text-sm text-[#518CFF]"
                            target="_blank"
                            href={social.link}
                          >
                            {social.link}
                          </a>
                        )}
                      </div>
                      <button
                        onClick={() => {
                          setSocial({
                            link: "",
                          });
                          setSocialShow(true);
                        }}
                      >
                        <img src="/img/trash.svg" alt="trash" />
                      </button>
                    </div>
                  </div>
                )}
                {socialShow && (
                  <div>
                    <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                    <div className="flex items-center justify-between gap-2">
                      <div className="w-full">
                        <label className="text-sm text-[#293951]">
                          Линк оруулна уу.
                        </label>
                        <div className="relative">
                          <MdOutlineInsertLink className="absolute left-2 top-[5px] text-[#293951] text-2xl" />
                          <input
                            className="w-full py-2 pe-4 ps-8 border rounded-md text-xs"
                            type="text"
                            value={socialLink}
                            onChange={(e) => setSocialLink(e.target.value)}
                            onKeyDown={handleKeyDownSocial}
                          />
                        </div>
                      </div>
                    </div>
                    {socialLink !== "" && socialShow && (
                      <div className="flex justify-end mt-2">
                        <button
                          onClick={handleSaveSocial}
                          className="px-5 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-sm"
                        >
                          Хадгалах
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-[45%] px-6 py-3 bg-white shadow-custom rounded-xl">
            <p className="text-[#293951] font-semibold mb-6">
              Ерөнхий мэдээлэл
            </p>
            <div className="mt-4 flex items-center gap-4">
              <div className="relative">
                <div
                  className="w-16 h-16 mb-4 rounded-full overflow-hidden border border-[#cfcfcf] cursor-pointer"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  {loadingImage && (
                    <AiOutlineLoading className="animate-spin text-blue-500 w-8 h-8 absolute top-4 left-4 m-auto" />
                  )}

                  {image ? (
                    <img
                      src={image}
                      alt="Profile"
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "100%",
                        height: "100%",
                        fontSize: "24px",
                      }}
                      firstName={user.firstName}
                      lastName={user.lastName}
                    />
                  )}
                </div>

                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />

                {error && (
                  <div className="absolute bottom-0 w-[300px]">
                    <p className="mt-2 text-xs text-red-500">{error}</p>
                  </div>
                )}
              </div>

              <div className="mb-2">
                <p className="text-[#1E293B] text-sm">
                  {user.lastName} {user.firstName}
                </p>
                <p className="text-[#666874] text-sm">
                  {user.departmentName}, {user.position}
                </p>
              </div>
            </div>

            <div className="mb-4 mt-5 flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <p className="text-[#1E293B] text-xs">Мейл хаяг</p>
                <p className="text-[#666874] text-xs">{user.email}</p>
              </div>
              <hr className="bg-[#F3F4F6]" />
              <div className="flex items-center justify-between">
                <p className="text-[#1E293B] text-xs">Ажиллаж эхэлсэн огноо</p>
                <p className="text-[#666874] text-xs">2024/04/01</p>
              </div>
              <hr className="bg-[#F3F4F6]" />
              <div className="flex items-center justify-between">
                <p className="text-[#1E293B] text-xs">Хэлтэс</p>
                <p className="text-[#666874] text-xs">{user.departmentName}</p>
              </div>
              <hr className="bg-[#F3F4F6]" />
              <div className="flex items-center justify-between">
                <p className="text-[#1E293B] text-xs">Баг</p>
                <p className="text-[#666874] text-xs">{user.teamName}</p>
              </div>
              {/* <hr className="bg-[#F3F4F6]" />
              <div className="flex items-center justify-between">
                <p className="text-[#1E293B] text-xs">Дээд албан тушаалтан</p>
                <p className="text-[#666874] text-xs">Employee name</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="px-8 py-5 bg-white shadow-custom rounded-xl w-full">
          <p className="text-[#293951] ">Нууц үг солих</p>
          <div className="flex flex-col items-start mt-4 px-20 gap-2 w-full">
            <div className="flex items-center justify-between gap-10 w-full">
              <div className="w-[45%]">
                <label className="text-[#293951] text-sm" htmlFor="oldPassword">
                  Одоогийн нууц үг оруулна уу.
                </label>
                <div className="relative">
                  <img
                    className="absolute top-1 left-2"
                    src="/img/key.svg"
                    alt="key"
                  />
                  <input
                    id="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    className="w-full py-2 ps-10 pe-4 border rounded-md"
                    type={!showOld ? "password" : "text"}
                    required
                    aria-describedby="oldPasswordHelp"
                  />
                  <button
                    onClick={() => setShowOld(!showOld)}
                    className="absolute top-2.5 right-2"
                    aria-label="Toggle password visibility"
                  >
                    {showOld ? (
                      <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                    ) : (
                      <VscEye className="text-[#ABB1BB] text-xl" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between gap-10 w-full">
              <div className="w-[45%]">
                <label className="text-[#293951] text-sm">
                  Шинэ нууц үг оруулна уу.
                </label>
                <div className="relative">
                  <img
                    className="absolute top-1 left-2"
                    src="/img/key.svg"
                    alt="key"
                  />
                  <input
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    className="w-full py-2 ps-10 pe-4 border rounded-md"
                    type={!showNew ? "password" : "text"}
                  />
                  <button
                    onClick={() => {
                      setShowNew(!showNew);
                    }}
                    className="absolute top-2.5 right-2 "
                  >
                    {showNew ? (
                      <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                    ) : (
                      <VscEye className="text-[#ABB1BB] text-xl" />
                    )}
                  </button>
                </div>
              </div>
              <div className="w-[45%]">
                <label className="text-[#293951] text-sm">
                  Шинэ нууц үг давтан оруулна уу.
                </label>
                <div className="relative">
                  <img
                    className="absolute top-1 left-2"
                    src="/img/key.svg"
                    alt="key"
                  />
                  <input
                    className="w-full py-2 ps-10 pe-4 border rounded-md"
                    value={repeatPassword}
                    onChange={(e) => {
                      setRepeatPassword(e.target.value);
                    }}
                    type={!showRepeat ? "password" : "text"}
                  />
                  <button
                    onClick={() => {
                      setShowRepeat(!showRepeat);
                    }}
                    className="absolute top-2.5 right-2 "
                  >
                    {showRepeat ? (
                      <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                    ) : (
                      <VscEye className="text-[#ABB1BB] text-xl" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-6 mt-4">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="text-[#1E293B] border border-[#EDEDED] bg-[#fff] rounded-lg px-6 py-2"
          >
            Буцах
          </button>
          <button
            onClick={handleSave}
            className="text-[#EDEDED] bg-[#324D72] rounded-lg px-6 py-2"
          >
            Хадгалах
          </button>
        </div>
      </div>
      <div className="block sm:block md:hidden">
        <div className="shadow-custom p-2 rounded-lg mb-4">
          <p className="text-[#293951] text-sm font-semibold mb-6">
            Ерөнхий мэдээлэл
          </p>
          <div className="flex items-center gap-2">
            <div className="relative">
              <div
                className="w-16 h-16 mb-4 rounded-full overflow-hidden border border-[#cfcfcf] cursor-pointer"
                onClick={() => document.getElementById("fileInput").click()}
              >
                {loadingImage && (
                  <AiOutlineLoading className="animate-spin text-blue-500 w-8 h-8 absolute top-4 left-4 m-auto" />
                )}

                {image ? (
                  <img
                    src={image}
                    alt="Profile"
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <Avatar
                    style={{
                      width: "100%",
                      height: "100%",
                      fontSize: "24px",
                    }}
                    firstName={user.firstName}
                    lastName={user.lastName}
                  />
                )}
              </div>

              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />

              {error && (
                <div className="absolute bottom-0 w-[300px]">
                  <p className="mt-2 text-xs text-red-500">{error}</p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <p className="text-[#1E293B] text-xs font-semibold">
                {user.lastName} {user.firstName}
              </p>
              <p className="text-xs text-[#666874]">{user.position}</p>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Мейл хаяг</p>
              <p className="text-[#1E293B] text-xs">{user.email}</p>
            </div>
            <hr className="text-[#F3F4F6] h-[1px] my-2" />

            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Хэлтэс</p>
              <p className="text-[#1E293B] text-xs">{user.departmentName}</p>
            </div>
            <hr className="text-[#F3F4F6] h-[1px] my-2" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Баг</p>
              <p className="text-[#1E293B] text-xs">{user.teamName}</p>
            </div>
            {/* <hr className="text-[#F3F4F6] h-[1px] my-2" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Дээд албан тушаалтан</p>
              <p className="text-[#1E293B] text-xs"></p>
            </div> */}
          </div>
        </div>
        <div className="shadow-custom p-2 rounded-lg mb-4">
          <p className="text-[#293951] text-sm mb-6">Нэмэлт мэдээлэл</p>
          <div className="flex items-center justify-between gap-4 mb-4">
            <div className="w-[48%]">
              <p className="text-xs text-[#293951] font-semibold">
                Төрсөн өдөр
              </p>
              <div className="relative">
                <MdOutlineCake className="absolute top-2.5 text-[#293951] text-opacity-70 left-1" />
                <input
                  value={birthday}
                  onChange={(e) => {
                    setBirthday(e.target.value);
                  }}
                  className="w-full ps-6 py-2 border rounded-lg text-xs"
                  type="date"
                />
              </div>
            </div>
            <div className="w-[48%]">
              <p className="text-xs text-[#293951] font-semibold">
                Утасны дугаар
              </p>
              <div className="relative">
                <MdOutlineLocalPhone className="absolute top-2.5 text-[#293951] text-opacity-70 left-1" />
                <input
                  value={phone !== 0 ? phone : ""}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  placeholder="9999-9999"
                  className="w-full ps-6 py-2 border rounded-lg text-xs"
                  type="number"
                />
              </div>
            </div>
          </div>
          {/* <div className="w-full border border-[#EFEFEF] rounded-lg p-2 mb-4">            <div className="py-1 flex items-center justify-between">
              <p className="text-[#293951] text-xs font-semibold">
                Сонирхол/Хобби
              </p>
              <button
                onClick={() => setHobbyShow(!hobbyShow)}
                className="text-[#293951]"
              >
                <FaPlus
                  className={`transform transition-transform duration-200 ${
                    !hobbyShow ? "rotate-45" : "rotate-0"
                  }`}
                />
              </button>
            </div>
            {!hobbyShow && (
              <div className="mt-2 flex items-center justify-between gap-4">
                <input
                  className="border border-[#EFEFEF] rounded-lg p-2 text-xs w-full"
                  type="text"
                  value={newHobby}
                  onChange={(e) => setNewHobby(e.target.value)}
                />
                <button onClick={handleAddHobby} className="text-[#293951]">
                  <FaPlus />
                </button>
              </div>
            )}
            {hobby.length !== 0 && (
              <div className="flex items-center gap-2 mt-1 flex-wrap">
                {hobby.map((items, index) => (
                  <div
                    key={index}
                    className="border border-[#324D72] px-1 py-0.5 flex items-center rounded-lg"
                  >
                    <p
                      className="text-[#324d72] text-xs cursor-pointer"
                      onClick={() => handleEditHobby(index)}
                    >
                      {items}
                    </p>
                    <button
                      className="text-base text-[#324D72]"
                      onClick={() => handleDeleteHobby(index)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                ))}
              </div>
            )}
            {hobby.length !== 0 && !hobbyShow && (
              <div className="flex justify-end mt-2">
                <button
                  onClick={() => {
                    setHobbyShow(false);
                  }}
                  className="px-3 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-xs"
                >
                  Хадгалах
                </button>
              </div>
            )}
          </div> */}
          <div className="w-full border border-[#EFEFEF] rounded-lg p-2">
            <div className="py-1 flex items-center justify-between">
              <p className="text-[#293951] text-xs font-semibold">
                Social хаяг
              </p>
              {social.link === "" ? (
                <button
                  onClick={() => {
                    setSocialShow(!socialShow);
                  }}
                  className="text-[#293951]"
                >
                  <FaPlus
                    className={`transform transition-transform duration-200 ${
                      !socialShow ? "rotate-45" : "rotate-0"
                    }`}
                  />
                </button>
              ) : (
                ""
              )}
            </div>
            {social.link !== "" ? (
              <div>
                <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <MdOutlineInsertLink className="text-[#293951] " />
                    {social.link && (
                      <a
                        rel="noreferrer"
                        className="underline text-xs text-[#518CFF]"
                        target="_blank"
                        href={social.link}
                      >
                        {social.link}
                      </a>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      setSocial({
                        link: "",
                      });
                      setSocialShow(!socialShow);
                    }}
                  >
                    <img
                      className="h-[14px]"
                      src="/img/trash.svg"
                      alt="trash"
                    />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {!socialShow && (
              <div>
                <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                <div className="flex items-center justify-between gap-2">
                  <div className="w-full">
                    <label className="text-sm text-[#293951]">
                      Линк оруулна уу.
                    </label>
                    <div className="relative">
                      <MdOutlineInsertLink className="absolute left-2 top-[5px] text-[#293951] text-2xl" />
                      <input
                        className="w-full py-2 pe-4 ps-8 border rounded-md text-xs"
                        type="text"
                        value={socialLink}
                        onChange={(e) => setSocialLink(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {socialLink !== "" && !socialShow && (
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={handleSaveSocial}
                      className="px-3 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-xs"
                    >
                      Хадгалах
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="shadow-custom p-2 rounded-lg mb-4">
          <p className="text-[#293951] text-sm mb-6">Нууц үг солих</p>
          <div className="mb-4">
            <p className="text-[#293951] text-xs mb-1">
              Одоогийн нууц үг оруулна уу.
            </p>
            <div className="relative">
              <MdOutlineLock className="text-[#666874] absolute top-2.5 left-1" />
              <input
                className="text-xs border border-[#EFEFEF] text-[#666874] py-2 ps-6 pe-6 w-full rounded-lg"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                type={showOld ? "text" : "password"}
              />
              <button
                onClick={() => {
                  setShowOld(!showOld);
                }}
                className="text-[#666874] absolute top-2.5 right-1 "
              >
                {" "}
                {!showOld ? <VscEye /> : <VscEyeClosed />}
              </button>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-[#293951] text-xs mb-1">
              Шинэ нууц үг оруулна уу.
            </p>
            <div className="relative">
              <MdOutlineLock className="text-[#666874] absolute top-2.5 left-1" />
              <input
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                className="text-xs border border-[#EFEFEF] text-[#666874] py-2 ps-6 pe-6 w-full rounded-lg"
                type={showNew ? "text" : "password"}
              />
              <button
                onClick={() => {
                  setShowNew(!showNew);
                }}
                className="text-[#666874] absolute top-2.5 right-1 "
              >
                {" "}
                {!showNew ? <VscEye /> : <VscEyeClosed />}
              </button>
            </div>
            {newPassword !== "" && newPassword.length < 4 && (
              <p className="text-[#EB5757] text-xs">
                Нууц үг 4 өөс дээш оронтой байх ёстой
              </p>
            )}
          </div>
          <div className="mb-4">
            <p className="text-[#293951] text-xs mb-1">
              Нууц үг нь 4-ээс дээш тэмдэгттэй байх ёстой
            </p>
            <div className="relative">
              <MdOutlineLock className="text-[#666874] absolute top-2.5 left-1" />
              <input
                value={repeatPassword}
                onChange={(e) => {
                  setRepeatPassword(e.target.value);
                }}
                className="text-xs border border-[#EFEFEF] text-[#666874] py-2 ps-6 pe-6 w-full rounded-lg"
                type={showRepeat ? "text" : "password"}
              />
              <button
                onClick={() => {
                  setShowRepeat(!showRepeat);
                }}
                className="text-[#666874] absolute top-2.5 right-1 "
              >
                {" "}
                {!showRepeat ? <VscEye /> : <VscEyeClosed />}
              </button>
            </div>
            {newPassword !== repeatPassword && repeatPassword !== "" && (
              <p className="text-[#EB5757] text-xs">Нууц үг таарахгүй байна</p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end mb-4 gap-4">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="flex items-center gap-2 border border-[#EB5757] text-[#EB5757] text-sm rounded-lg py-2 px-3 "
          >
            <CiLogout className="transform transition-transform rotate-180 font-black" />
            Гарах
          </button>
          <button
            onClick={handleSave}
            className="text-[#EDEDED] text-sm py-2 px-3 bg-[#324D72] rounded-lg"
          >
            Хадгалах
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
