import React from "react";

function Avatar({ firstName, lastName, style }) {
  const initials = `${firstName?.[0] || ""}${lastName?.[0] || ""}`;

  return (
    <div
      style={style}
      className="flex items-center justify-center w-[40px] h-[40px] bg-[#666874] text-white font-bold  rounded-full"
    >
      {initials.toUpperCase()}
    </div>
  );
}

export default Avatar;
