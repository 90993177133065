import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../service/user.service";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setLoading(true);

    e.preventDefault();
    login({
      username: username,
      password: password,
    })
      .then((res) => {
        if (res.data.accessToken) {
          const token = res.data.accessToken;
          Cookies.set("metacog_token", token, { expires: 7 });

          navigate({ pathname: "/" });
          setLoading(false);
        } else {
          toast.error("Invalid username or passwordsss");
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Invalid username or password");
        setLoading(false);
      });
  };

  return (
    <div className="bg-[#fff]">
      <div className="h-screen w-11/12 m-auto pt-6 sm:hidden hidden md:block">
        <div className="flex items-center ">
          <img className="w-[30px] mt-1" src="/img/logo.svg" alt="logo" />
          <p className="text-[#000] font-bold text-[24px]">myspace</p>
        </div>
        <div className="flex justify-between items-center gap-10 mt-10 px-10 relative pb-6">
          <div className="mb-40">
            <p className="text-[#000] text-[50px] font-bold">
              Өөрийн орон зайд
            </p>
            <p className="text-[#000] text-[35px]">тавтай морилно уу!</p>
            <p className="mt-10">Хэрвээ танд бүртгэлтэй хаяг байхгүй бол</p>
            <p>хүний нөөцийн албатай холбогдоно уу.</p>
          </div>
          <div
            style={{ backdropFilter: "blur(17.5px)" }}
            className="border rounded-[40px] pb-6 pt-10 px-8 border-[#C4C4C4] bg-[#fff] bg-opacity-30 w-[539px]"
          >
            <div className="flex w-full items-center justify-between">
              <p className="text-[#000] text-[20px]">Тавтай морилно уу!</p>
              {/* <select className=" border border-[#CECFD3] rounded-md text-[#000] text-sm p-1">
                <option value="en">EN</option>
                <option value="mn">MN</option>
              </select> */}
            </div>
            <p className="text-[#1E293B] text-[48px] font-bold">Нэвтрэх</p>
            <form className="mt-8" onSubmit={handleSubmit}>
              <div className="flex flex-col mb-4">
                <label className="text-[#000] mb-2">
                  Бүртгэлтэй мейл хаягаа оруулна уу.
                </label>
                <input
                  className="border border-xl rounded-lg ps-[25px] py-[19px] text-sm"
                  type="text"
                  value={username}
                  placeholder="И-мейл"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col mb-2">
                <label className="text-[#000] mb-2">
                  Нууц үгээ оруулна уу.
                </label>
                <div className="relative">
                  <input
                    className="border border-xl rounded-lg ps-[25px] py-[19px] text-sm w-full"
                    type={show ? "text" : "password"}
                    value={password}
                    placeholder="Нууц үг"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div
                    onClick={() => {
                      setShow(!show);
                    }}
                    className="absolute right-4 top-5 cursor-pointer"
                  >
                    {show ? (
                      <IoEyeOutline className="text-2xl text-[#8E8E8E]" />
                    ) : (
                      <IoEyeOffOutline className="text-2xl text-[#8E8E8E]" />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                {/* <button className="text-[#AD3113] text-[13px]">
                  Нууц үг мартсан
                </button> */}
              </div>
              <button
                className="border rounded-xl px-4 py-4 bg-[#324D72] text-[#fff] mt-8 flex items-center justify-center w-full"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                    ></path>
                  </svg>
                ) : (
                  "Нэвтрэх"
                )}
              </button>
              <div className="my-2 w-full flex items-center justify-center gap-2">
                <div className="bg-[#666] w-[48%] h-[2px] bg-opacity-25" />
                <p className="text-[#666] text-sm">эсвэл</p>
                <div className="bg-[#666] w-[48%] h-[2px] bg-opacity-25" />
              </div>
              <button
                className="border border-[#A5A5A5] rounded-xl px-4 py-4 bg-[#fff] text-[#000]  flex items-center justify-center w-full"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                    ></path>
                  </svg>
                ) : (
                  <div className="flex items-center gap-2">
                    <img src="/img/ microsoft.svg" alt="microsoft" />
                    <p className="text-[#1E293B]">
                      Sign in with Microsoft (Coming Soon)
                    </p>
                  </div>
                )}
              </button>
            </form>
          </div>
          <p className="absolute right-8 -bottom-10">
            Нэвтэрснээрээ та манай{" "}
            <span className="underline">нууцлалын бодлогыг</span>  зөвшөөрч
            байна гэсэн үг.
          </p>
          <img
            className="absolute -bottom-20 left-[37%] transform -translate-x-1/2"
            src="/img/login/login.svg"
            alt="human"
          />
        </div>
      </div>
      <div className="w-full h-screen  items-center justify-center sm:flex flex md:hidden relative">
        <div>
          <div className="flex items-center mb-6">
            <img className="mt-1" src="/img/logo.svg" alt="logo" />
            <p className="text-lg font-bold">myspace</p>
          </div>
          <div className="flex justify-between mb-8">
            <div>
              <p className="text-sm">Тавтай морилно уу!</p>
              <p className="text-[26px] font-semibold">Нэвтрэх</p>
            </div>
            <div>
              {/* <select className="m-0 border border-[#CECFD3] rounded-md  bg-none p-1">
                <option value="eng">ENG</option>
                <option value="mng">MNG</option>
              </select> */}
            </div>
          </div>
          <div className="mb-20">
            <p className="text-sm ">
              Хэрвээ танд бүртгэлтэй хаяг байхгүй бол
              <br />
              хүний нөөцийн албатай холбогдоно уу.
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-2">
              <input
                className="border border-xl w-[330px] text-[#3D608C] bg-[#F4F6FB] rounded-lg px-4 py-2"
                type="text"
                placeholder="Бүртгэлтэй и-мейл хаяг"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <div className="relative">
                <input
                  className="border border-xl w-[330px] text-[#3D608C] bg-[#F4F6FB] rounded-lg  py-2 ps-4 pe-7"
                  type={show ? "text" : "password"}
                  value={password}
                  placeholder="Нууц үг"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div
                  onClick={() => {
                    setShow(!show);
                  }}
                  className="absolute right-2 top-3 cursor-pointer"
                >
                  {show ? (
                    <IoEyeOutline className="text-xl text-[#3D608C]" />
                  ) : (
                    <IoEyeOffOutline className="text-xl text-[#3D608C]" />
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              {/* <button className="text-[13px] text-[#B0B0B0]">
                Нууц үг мартсан
              </button> */}
            </div>
            <button
              className="border rounded-xl mt-16 w-full px-4 py-2 bg-[#324D72] text-white text-sm  flex items-center justify-center"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                  ></path>
                </svg>
              ) : (
                "Нэвтрэх"
              )}
            </button>
            <div className="flex items-center gap-2 py-4">
              <div className="bg-[#666666] w-[48%] bg-opacity-25 h-[2px]" />
              <p className="text-[#666666] text-sm">эсвэл</p>
              <div className="bg-[#666666] w-[48%] bg-opacity-25 h-[2px]" />
            </div>
            <button
              className="border border-[#CECFD3] rounded-xl px-4 py-2 bg-[#fff] text-[#000]  flex items-center justify-center w-full"
              type="submit"
              disabled={true}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                  ></path>
                </svg>
              ) : (
                <div className="flex items-center gap-2">
                  <img src="/img/ microsoft.svg" alt="microsoft" />
                  <p className="text-[#1E293B]">
                    Sign in with Microsoft (Coming Soon)
                  </p>
                </div>
              )}
            </button>
          </form>
        </div>
        <div className="absolute bottom-4 left-1/2 transform w-full -translate-x-1/2">
          <p className="text-[10px] mt-6 text-center">
            Нэвтэрснээрээ та манай{" "}
            <span className="underline">нууцлалын бодлогыг</span> <br />
            зөвшөөрч байна гэсэн үг.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
