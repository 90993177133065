import React, { useState } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { FaChevronLeft, FaRegBell } from "react-icons/fa6";
import SurvTask from "../../../components/SurvTask";
import { FaChevronRight } from "react-icons/fa6";
// import { useNavigate } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { MdEdit } from "react-icons/md";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

const MobileHome = () => {
  const [hover, setHover] = useState(0);
  const [chosen, setChosen] = useState(0);
  const [text, setText] = useState("");
  const [saved, setSaved] = useState(null);
  const [history, setHistory] = useState(false);
  // const navigate = useNavigate();

  const surv = {
    name: "survey",
    date: "2024/02/12",
    questionCount: 7,
    completedCount: 1,
  };

  const handleSave = () => {
    setSaved({
      feels: chosen,
      text: text,
    });
    setChosen(0);
    setText(0);
  };

  const handleEdit = () => {
    setText(saved.text);
    setChosen(saved.feels);
    setSaved(null);
  };

  const data = {
    labels: ["Дав", "Мяг", "Лха", "Пүр", "Ба", "Бя", "Ня"],
    datasets: [
      {
        label: "Emotion Levels",
        data: [1, 3, 2, 2, 1, 4, 5],
        borderColor: "#CECFD3",
        backgroundColor: "#CECFD3",
        pointBackgroundColor: "#5A6ACF",
        pointBorderColor: "#5A6ACF",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 1,
        max: 5,
        ticks: {
          stepSize: 1, // Ensure ticks are whole numbers
          display: false, // Hide the default numeric y-ticks
        },
        grid: {
          color: "#D9DCDC",
        },
        border: {
          dash: [8, 4],
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      {!history ? (
        <div className="mt-6 w-full flex flex-col items-start justify-between">
          <div
            className={`bg-[#F4F6FB] px-4 py-4 rounded-xl w-full relative mb-6`}
          >
            {saved === null ? (
              <div className="flex items-center justify-center">
                <div>
                  {chosen === 0 && (
                    <p className="mb-2 text-center text-sm text-[#1E293B]">
                      Өнөөдөр хэр байна даа?
                    </p>
                  )}
                  <div className="flex items-center justify-center gap-2">
                    <button
                      onMouseEnter={() => {
                        setHover(1);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(1);
                      }}
                    >
                      <img
                        className={`${hover === 1 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 1 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji1.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(2);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(2);
                      }}
                    >
                      <img
                        className={`${hover === 2 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 2 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji2.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(3);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(3);
                      }}
                    >
                      <img
                        className={`${hover === 3 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 3 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji3.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(4);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(4);
                      }}
                    >
                      <img
                        className={`${hover === 4 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 4 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji4.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(5);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(5);
                      }}
                    >
                      <img
                        className={`${hover === 5 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 5 ? "w-[40px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji5.gif"
                        alt="emoji"
                      />
                    </button>
                  </div>
                  {chosen !== 0 && (
                    <div>
                      <p className="text-center text-[##1E293B] text-xs">
                        Яагаад ийм мэдрэмж мэдэрч буйгаа тэмдэглэл болгон хөтлөх
                        үү? 😌
                      </p>
                      <div className="flex justify-center mt-2 ">
                        <textarea
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                          value={text}
                          className="text-xs w-full h-[90px] px-4 py-2 rounded-xl"
                          placeholder="Таны өгсөн хариулт зөвхөн танд л харагдахаар загварчлагдсан тул чөлөөтэй байгаарай!"
                        ></textarea>
                      </div>
                      {text !== "" && (
                        <div className="flex justify-end mt-4">
                          <button
                            onClick={handleSave}
                            className="text-[#fff] text-sm bg-[#324D72] px-3 py-1 rounded-lg "
                          >
                            хадгалах
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-4">
                <img
                  className="w-[40px]"
                  src={`/img/home/emoji${saved.feels}.gif`}
                  alt="emoji"
                />
                <div>
                  <p className="text-[#ABADB5] text-xs">2024/10/01</p>
                  <p className="text-[#6B7280] text-xs max-w-[300px] max-h-[200px] overflow-y-auto">
                    {saved.text}
                  </p>
                </div>
                <button onClick={handleEdit}>
                  <MdEdit className="w-[20px]" />
                </button>
              </div>
            )}
            {chosen === 0 && !history && (
              <div className="flex justify-end">
                <button
                  onClick={() => setHistory(true)}
                  className="text-[#324d72] text-sm mt-2 border-b border-[#324d72] "
                >
                  өмнөх түүх харах
                </button>
              </div>
            )}
          </div>
          <hr className="text-[#CEDAE9] h-[2px] w-full my-6" />
          <div className="">
            <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
              <FaRegBell />
              Сүүлийн үеийн шинэчлэлүүд
            </p>

            <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
              <div className="flex items-center justify-between">
                <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

                <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                  <p className="text-[#FF9D72] font-semibold text-sm">new</p>
                </div>
              </div>
              <div>
                <p className="text-xs text-[#222] py-2">
                  Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                  дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
                </p>
              </div>
              <div className="flex items-center gap-2 mt-1">
                <div className="p-1 border border-[#000] bg-white rounded-full">
                  <LuUser2 className="text-xl text-[#000]" />
                </div>
                <div>
                  <p className="text-[#1E293B] text-xs">Овог Нэр</p>
                  <p className="text-[#64748B] text-xs">Хэлтэс албан тушаал</p>
                </div>
              </div>
            </div>
            <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
              <div className="flex items-center justify-between">
                <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

                <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                  <p className="text-[#FF9D72] font-semibold text-sm">new</p>
                </div>
              </div>
              <div>
                <p className="text-xs text-[#222] py-2">
                  Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                  дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
                </p>
              </div>
              <div className="flex items-center gap-2 mt-1">
                <img src="/img/incognito.svg" alt="incognito" />

                <div>
                  <p className="text-[#1E293B] text-xs">Нэрээ нууцалсан</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-[#CEDAE9] h-[2px] w-full my-6" />
          <div className="">
            <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
              <TfiMenuAlt />
              Гүйцэтгэх шаардлагатай зүйлс
            </p>
          </div>
        </div>
      ) : (
        <div>
          <button
            onClick={() => {
              setHistory(false);
            }}
            className="text-[#1E293B] text-xs flex items-center gap-2 mb-4"
          >
            <FaChevronLeft /> Буцах
          </button>
          <div className="bg-[#F4F6FB] px-4 py-4 mb-6 rounded-md">
            <div className="flex justify-center">
              {saved === null ? (
                ""
              ) : (
                <img
                  className="w-[35px]"
                  src={`/img/home/emoji${saved.feels}.gif`}
                  alt="emoji"
                />
              )}
            </div>
            <p className="text-xs text-[#ABADB5]">2024/10/01</p>
            <p className="text-xs text-[#6B7280] w-full max-h-[100px] overflow-y-auto">
              {saved?.text}
            </p>
          </div>
          <div style={{ width: "full", height: "auto" }}>
            <Line data={data} options={options} />
          </div>
          <div className="flex justify-center items-center mt-4 gap-2">
            <button className="text-xs">
              <FaChevronLeft />
            </button>
            <p className="text-[#777985] text-xs flex items-center gap-2">
              10 дугаар сар, 1-р 7 хоног
            </p>
            <button className="text-xs">
              <FaChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHome;
