import React, { useState } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { FaChevronLeft, FaRegBell } from "react-icons/fa6";
import SurvTask from "../../../components/SurvTask";
import { FaChevronRight } from "react-icons/fa6";
// import { useNavigate } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { MdEdit } from "react-icons/md";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import Modal from "../../Modal";

Chart.register(...registerables);

const imageLabelPlugin = {
  id: "imageLabelPlugin",
  afterUpdate: (chart) => {
    const { ctx, chartArea } = chart;
    const yScale = chart.scales?.y;

    if (!yScale || !chartArea) return;

    const yLabels = yScale.ticks;
    if (!yLabels || !Array.isArray(yLabels)) return;

    ctx.clearRect(chartArea.left - 50, chartArea.top, 50, chartArea.height);

    yLabels.forEach((label, index) => {
      const roundedValue = Math.round(label.value);
      const imgPath = `/img/home/emoji${roundedValue}.gif`;

      const img = new Image();
      img.src = imgPath;

      img.onload = () => {
        const xPos = Math.max(0, chartArea.left - 30);
        let yPos = yScale.getPixelForTick(index) - 10;

        yPos = Math.max(chartArea.top, Math.min(yPos, chartArea.bottom - 20));

        if (xPos >= 0 && yPos >= chartArea.top && yPos <= chartArea.bottom) {
          ctx.drawImage(img, xPos, yPos, 20, 20);
        }
      };
    });
  },
};

Chart.register(imageLabelPlugin);

const DesktopHome = () => {
  const [hover, setHover] = useState(0);
  const [chosen, setChosen] = useState(0);
  const [text, setText] = useState("");
  const [saved, setSaved] = useState(null);
  const [history, setHistory] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const surv = {
    name: "survey",
    date: "2024/02/12",
    questionCount: 7,
    completedCount: 1,
  };

  const handleSave = () => {
    setSaved({
      feels: chosen,
      text: text,
    });
    setChosen(0);
    setText(0);
  };

  const handleEdit = () => {
    setText(saved.text);
    setChosen(saved.feels);
    setSaved(null);
  };

  const data = {
    labels: ["Дав", "Мяг", "Лха", "Пүр", "Ба", "Бя", "Ня"],
    datasets: [
      {
        label: "Emotion Levels",
        data: [1, 3, 2, 2, 1, 4, 5],
        borderColor: "#CECFD3",
        backgroundColor: "#CECFD3",
        pointBackgroundColor: "#5A6ACF",
        pointBorderColor: "#5A6ACF",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 1,
        max: 5,
        ticks: {
          stepSize: 1, // Ensure ticks are whole numbers
          display: false, // Hide the default numeric y-ticks
        },
        grid: {
          color: "#D9DCDC",
        },
        border: {
          dash: [8, 4],
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-center">
        <div
          className={`bg-[#F4F6FB] px-4 py-4 rounded-xl flex items-center justify-center relative ${
            history === false
              ? chosen === 0
                ? "w-[417px] h-[190px]"
                : "w-[623px] h-[260px]"
              : "w-[725px] h-auto"
          }`}
        >
          {
            // !history ? (
            saved === null ? (
              <div className="flex items-center justify-center">
                <div>
                  {chosen === 0 && (
                    <p className="mb-2 text-center text-sm text-[#1E293B]">
                      Өнөөдөр хэр байна даа?
                    </p>
                  )}
                  <div className="flex items-center justify-center gap-2">
                    <button
                      onMouseEnter={() => {
                        setHover(1);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(1);
                      }}
                    >
                      <img
                        className={`${hover === 1 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 1 ? "w-[55px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji1.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(2);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(2);
                      }}
                    >
                      <img
                        className={`${hover === 2 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 2 ? "w-[55px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji2.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(3);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(3);
                      }}
                    >
                      <img
                        className={`${hover === 3 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 3 ? "w-[55px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji3.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(4);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(4);
                      }}
                    >
                      <img
                        className={`${hover === 4 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 4 ? "w-[55px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji4.gif"
                        alt="emoji"
                      />
                    </button>
                    <button
                      onMouseEnter={() => {
                        setHover(5);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        setChosen(5);
                      }}
                    >
                      <img
                        className={`${hover === 5 ? "w-[40px]" : "w-[32px]"} ${
                          chosen === 5 ? "w-[55px]" : "w-[32px]"
                        }`}
                        src="/img/home/emoji5.gif"
                        alt="emoji"
                      />
                    </button>
                  </div>
                  {chosen !== 0 && (
                    <div>
                      <p className="text-center text-[##1E293B] text-sm">
                        Яагаад ийм мэдрэмж мэдэрч буйгаа тэмдэглэл болгон хөтлөх
                        үү? 😌
                      </p>
                      {/* <div className="flex justify-center mt-2 ">
                        <textarea
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                          value={text}
                          className="text-xs w-[403px] h-[90px] px-4 py-2 rounded-xl"
                          placeholder="Таны өгсөн хариулт зөвхөн танд л харагдахаар загварчлагдсан тул чөлөөтэй байгаарай!"
                        ></textarea>
                      </div>  */}
                      {/* {text !== "" && ( */}
                      <div className="flex justify-end mt-4">
                        <button
                          onClick={handleSave}
                          className="text-[#fff] text-sm bg-[#324D72] px-3 py-1 rounded-lg "
                        >
                          хадгалах
                        </button>
                      </div>
                      {/* )} */}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-4">
                <img
                  className="w-[40px]"
                  src={`/img/home/emoji${saved.feels}.gif`}
                  alt="emoji"
                />
                <div>
                  <p className="text-[#ABADB5] text-xs">2024/10/01</p>
                  {/* <p className="text-[#6B7280] text-xs max-w-[300px] max-h-[200px] overflow-y-auto">
                    {saved.text}
                  </p> */}
                </div>
                {/* <button onClick={handleEdit}>
                  <MdEdit className="w-[20px]" />
                </button> */}
              </div>
            )
            // ) : (
            //   <div>
            //     <button
            //       onClick={() => {
            //         setHistory(false);
            //       }}
            //       className="text-[#1E293B] text-sm flex items-center gap-1 mb-4"
            //     >
            //       <FaChevronLeft /> Буцах
            //     </button>
            //     <div className="flex items-center justify-between ">
            //       <div>
            //         {saved === null ? (
            //           ""
            //         ) : (
            //           <img
            //             className="w-[55px]"
            //             src={`/img/home/emoji${saved.feels}.gif`}
            //             alt="emoji"
            //           />
            //         )}
            //         <p className="text-xs text-[#ABADB5]">2024/10/01</p>
            //         <p className="text-xs text-[#6B7280] max-w-[230px] max-h-[60px] overflow-y-auto">
            //           Lorem Ipsum is simply dummy text of the printing and
            //           typesetting industry. Lorem Ipsum has been the industry's
            //           standard dummy text ever since the 1500s,{" "}
            //         </p>
            //       </div>
            //       <div style={{ width: "400px" }}>
            //         <Line data={data} options={options} />
            //       </div>
            //     </div>
            //   </div>
            // )
          }

          {/* {chosen === 0 && !history && (
            <button
              onClick={() => setHistory(true)}
              className="text-[#324d72] text-sm border-b border-[#324d72] absolute bottom-4 right-4"
            >
              өмнөх түүх харах
            </button>
          )} */}
        </div>
      </div>
      {/* <hr className="text-[#CEDAE9] my-6" />
      <div className="mt-6 w-full flex items-start justify-between gap-10">
        <div className="w-[50%]">
          <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
            <TfiMenuAlt />
            Гүйцэтгэх шаардлагатай зүйлс
          </p>
          <div className="mb-6 shadow-xl bg-white px-4 py-2 flex items-center gap-4 rounded-xl">
            <div className="w-[40%]">
              <div className="flex items-start gap-2 ">
                <img src="/img/home/star.svg" alt="star" />
                <div>
                  <p className="font-semibold text-sm text-[#222]">Task name</p>
                  <p className="text-xs text[#222] opacity-80 mb-8">2 tasks</p>
                  <div className="ms-5 relative flex items-center justify-center bg-[#EEEFF1] border-[#324D72] border rounded-xl w-[25px] h-[15px]">
                    <img
                      className="absolute bottom-2"
                      src="./img/location.svg"
                      alt="location"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[70%]">
              <div className="flex justify-end">
                <p className="text-[#FF9D72] bg-[#FFF3ED] w-auto font-semibold mb-6 text-xs px-1.5 py-0.5 me-3 rounded-md text-end">
                  4 өдөр үлдсэн
                </p>
              </div>
              <p className="text-[#636466] text-xs">Эцсийн хугацаа:</p>
              <p className="text-[#222] text-xs font-semibold mb-3">
                2024/11/02
              </p>
              <button className="text-xs text-[#636466] flex items-center gap-2">
                Үргэлжлүүлэх <FaChevronRight />
              </button>
            </div>
          </div>
          <div className="flex items-center gap-4 py-2 px-5 bg-white rounded-xl shadow-xl mb-6">
            <img src="/img/home/star.svg" alt="star" />
            <div>
              <p className="text-[#636466] font-semibold text-sm mb-3">
                Task name- 2 даалгавар дутуу
              </p>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Ажлын хувцас хүлээн авах
                </p>
              </div>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Хөдөлмөрийн гэрээтэй танилцах
                </p>
              </div>
              <button className="mt-2 text-[#636466] text-xs flex items-center gap-2">
                Дэлгэрэнгүй
                <FaChevronRight />
              </button>
            </div>
          </div>
          <div>
            <SurvTask data={surv} />
          </div>
        </div>
        <div className="w-[50%]">
          <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
            <FaRegBell />
            Сүүлийн үеийн шинэчлэлүүд
          </p>

          <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
            <div className="flex items-center justify-between">
              <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

              <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                <p className="text-[#FF9D72] font-semibold text-sm">new</p>
              </div>
            </div>
            <div>
              <p className="text-xs text-[#222] py-2">
                Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
              </p>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <div className="p-1 border border-[#000] bg-white rounded-full">
                <LuUser2 className="text-xl text-[#000]" />
              </div>
              <div>
                <p className="text-[#1E293B] text-xs">Овог Нэр</p>
                <p className="text-[#64748B] text-xs">Хэлтэс албан тушаал</p>
              </div>
            </div>
          </div>
          <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
            <div className="flex items-center justify-between">
              <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

              <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                <p className="text-[#FF9D72] font-semibold text-sm">new</p>
              </div>
            </div>
            <div>
              <p className="text-xs text-[#222] py-2">
                Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
              </p>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <img src="/img/incognito.svg" alt="incognito" />

              <div>
                <p className="text-[#1E293B] text-xs">Нэрээ нууцалсан</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {popUp && <Modal modal={popUp} setModal={setPopUp} />}
    </div>
  );
};

export default DesktopHome;
