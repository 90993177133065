import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass, FaPlus } from "react-icons/fa6";
// import { IoMdClose } from "react-icons/io";
import { LuUser2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import "../../../style/animation.css";
import { likeButton } from "../../../service/feedback.service";

const HeartButton = ({ isLike, table, id }) => {
  const [animationStep, setAnimationStep] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [like, setLike] = useState(isLike);
  const [delayedLike, setDelayedLike] = useState(isLike);

  const startAnimation = async () => {
    setIsButtonDisabled(true);
    setLike(!like);

    if (like) {
      setAnimationStep("slideLeft");
      const slideLeftTimeout = setTimeout(() => setAnimationStep(""), 2000);
      await likeButton(id);
      setIsButtonDisabled(false);
      return () => {
        clearTimeout(slideLeftTimeout);
      };
    } else {
      setAnimationStep("slideIn");
      const slideInTimeout = setTimeout(() => setAnimationStep("shake"), 2000);
      const fadeOutTimeout = setTimeout(() => setAnimationStep(""), 4000);

      await likeButton(id);
      setIsButtonDisabled(false);
      return () => {
        clearTimeout(slideInTimeout);
        clearTimeout(fadeOutTimeout);
      };
    }
  };

  useEffect(() => {
    let likeTimeout;
    if (like) {
      likeTimeout = setTimeout(() => {
        setDelayedLike(true);
      }, 4000);
    } else {
      setDelayedLike(false);
    }

    return () => clearTimeout(likeTimeout);
  }, [like]);

  return (
    <div className="">
      <button
        onClick={startAnimation}
        className="flex items-center relative z-10"
        disabled={table === "sended" || isButtonDisabled}
      >
        {console.log("com", delayedLike, id, isLike)}
        {delayedLike === true ? (
          <img src="/img/feedback/hearth.svg" alt="heart" />
        ) : (
          <img src="/img/feedback/hearthGray.svg" alt="heart" />
        )}
      </button>

      {/* Animation container relative to button */}
      <div
        className={`heart-animation-container heart-animation ${
          animationStep === "slideLeft"
            ? "slideLeft-animation"
            : animationStep === "slideIn"
            ? "slideIn-animation"
            : animationStep === "shake"
            ? "shake-animation"
            : ""
        }`}
      >
        <img src="/img/feedback/hearth.svg" alt="heart" />
      </div>
    </div>
  );
};

const DesktopFeedback = ({ inboxData, sendData }) => {
  const height = window.innerHeight - 360;
  const [table, setTable] = useState("inbox");
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `/${year}/${month}/${day}`;
  }

  const isLatest = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();

    const timeDifference = currentDate - createdDate;
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

    return timeDifference <= threeDaysInMs;
  };

  const filterDataByText = (data) => {
    if (!data) return [];
    return data.filter((item) =>
      item.text.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const startGradiant = {
    background: "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)",
  };

  const conGradiant = {
    background: "linear-gradient(90deg, #48D7B4, #46DF9F, #01CF60)",
  };

  const stopGradiant = {
    background: "linear-gradient(90deg, #FFA354, #FF9840, #FF5C00)",
  };

  return (
    <div>
      <div>
        <div className="flex justify-end">
          <button
            onClick={() => {
              navigate("search");
            }}
            className="text-[#fff] text-sm flex items-center gap-2 px-3 py-2 rounded-md bg-[#324d72]"
          >
            <FaPlus />
            Фид-Форвард өгөх
          </button>
        </div>
        <div className="flex items-center gap-10 mb-2">
          <button
            onClick={() => {
              setTable("inbox");
            }}
            className={`flex items-center justify-center pb-1.5 ${
              table === "inbox" ? "border-b-2 border-[#324D72]" : ""
            }`}
          >
            <div>
              <p
                className={`text-sm ${
                  table === "inbox" ? "text-[#324d72]" : "text-[#666874]"
                }`}
              >
                Хүлээн авсан <span>[{inboxData.number}]</span>
              </p>
            </div>
          </button>
          <button
            onClick={() => {
              setTable("sended");
            }}
            className={`flex items-center justify-center pb-1.5 ${
              table === "sended" ? "border-b-2 border-[#324D72]" : ""
            }`}
          >
            <p
              className={`text-sm ${
                table === "sended" ? "text-[#324d72]" : "text-[#666874]"
              }`}
            >
              Илгээсэн <span>[{sendData.number}]</span>
            </p>
          </button>
        </div>
      </div>
      <hr className="bg-[#ECECEC] mb-6" />
      <div className="flex items-center justify-between">
        <div>
          <div className="flex relative">
            <div className="absolute top-1/2 left-3 transform  -translate-y-1/2 z-10">
              <FaMagnifyingGlass className="text-[#64748B] text-xs opacity-100" />
            </div>
            <input
              className="text-xs py-2 pe-4 rounded-xl border border-[#B3B3B3] ps-7"
              placeholder="Search for responses"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
      {table === "sended" ? (
        <div className="flex items-start gap-4 justify-between mt-6">
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={startGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Start</p>
              <div className="w-[25px] h-[25px] flex items-center justify-center bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {sendData.start?.length >= 99 ? "+99" : sendData.start?.length}
              </div>
            </div>
            <div style={startGradiant} className="w-full h-[4px] my-4" />
            {sendData.start?.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/img/feedback/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Илгээсэн Фид-Форвард байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(sendData.start).map((items) => {
                  return (
                    <div
                      key={items.id}
                      className="w-full p-4 bg-white rounded-xl mb-4 overflow-hidden animation-wrapper"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              new
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {items.hide ? (
                            <div className="flex items-center gap-2 mt-1">
                              <img
                                className="w-[25px]"
                                src="/img/incognito.svg"
                                alt="incognito"
                              />

                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  Нэрээ нууцалсан
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2 mt-1">
                              <div className="p-1 border border-[#000] bg-white rounded-full">
                                <LuUser2 className="text-sm text-[#000]" />
                              </div>
                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  {items.lastName} {items.firstName}
                                </p>
                                {/* <p className="text-[#64748B] text-xs">
                            Хэлтэс албан тушаал
                          </p> */}
                              </div>
                            </div>
                          )}
                        </div>

                        <HeartButton
                          isLike={items.isLike}
                          table={"sended"}
                          id={items.id}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={conGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Continue</p>
              <div className="w-[25px] h-[25px] flex items-center justify-center bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {sendData.con?.length >= 99 ? "+99" : sendData.con?.length}
              </div>
            </div>
            <div style={conGradiant} className="w-full h-[4px] my-4" />
            {sendData.con?.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/img/feedback/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Илгээсэн Фид-Форвард байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(sendData.con).map((items) => {
                  return (
                    <div
                      key={items.id}
                      className="w-full p-4 bg-white rounded-xl mb-4 animation-wrapper"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              new
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {" "}
                          {items.hide ? (
                            <div className="flex items-center gap-2 mt-1">
                              <img
                                className="w-[25px]"
                                src="/img/incognito.svg"
                                alt="incognito"
                              />

                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  Нэрээ нууцалсан
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2 mt-1">
                              <div className="p-1 border border-[#000] bg-white rounded-full">
                                <LuUser2 className="text-sm text-[#000]" />
                              </div>
                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  {items.lastName} {items.firstName}
                                </p>
                                {/* <p className="text-[#64748B] text-xs">
                            Хэлтэс албан тушаал
                          </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                        <HeartButton
                          isLike={items.isLike}
                          table={"sended"}
                          id={items.id}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%]  pb-4">
            <div className="flex items-center gap-2">
              <div
                style={stopGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Stop</p>
              <div className="w-[25px] h-[25px] flex items-center justify-center bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {sendData.stop?.length >= 99 ? "+99" : sendData.stop?.length}
              </div>
            </div>
            <div style={stopGradiant} className="w-full h-[4px] my-4" />
            {sendData.stop?.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/img/feedback/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Илгээсэн Фид-Форвард байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(sendData.stop).map((items) => {
                  return (
                    <div
                      key={items.id}
                      className="w-full p-4 bg-white rounded-xl mb-4 animation-wrapper"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              new
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {" "}
                          {items.hide ? (
                            <div className="flex items-center gap-2 mt-1">
                              <img
                                className="w-[25px]"
                                src="/img/incognito.svg"
                                alt="incognito"
                              />

                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  Нэрээ нууцалсан
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2 mt-1">
                              <div className="p-1 border border-[#000] bg-white rounded-full">
                                <LuUser2 className="text-sm text-[#000]" />
                              </div>
                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  {items.lastName} {items.firstName}
                                </p>
                                {/* <p className="text-[#64748B] text-xs">
                            Хэлтэс албан тушаал
                          </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                        <HeartButton
                          isLike={items.isLike}
                          table={"sended"}
                          id={items.id}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-start gap-4 justify-between mt-6">
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%]  pb-4">
            <div className="flex items-center gap-2">
              <div
                style={startGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Start</p>
              <div className="w-[25px] h-[25px] flex items-center justify-center bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {inboxData.start?.length >= 99
                  ? "+99"
                  : inboxData.start?.length}
              </div>
            </div>
            <div style={startGradiant} className="w-full h-[4px] my-4" />
            {inboxData.start?.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/img/feedback/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Хүлээн авсан Фид-Форвард байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(inboxData.start).map((items) => {
                  return (
                    <div
                      key={items.id}
                      className="w-full p-4 bg-white rounded-xl mb-4 animation-wrapper"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              new
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {" "}
                          {items.hide ? (
                            <div className="flex items-center gap-2 mt-1">
                              <img
                                className="w-[25px]"
                                src="/img/incognito.svg"
                                alt="incognito"
                              />

                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  Нэрээ нууцалсан
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2 mt-1">
                              <div className="p-1 border border-[#000] bg-white rounded-full">
                                <LuUser2 className="text-sm text-[#000]" />
                              </div>
                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  {items.lastName} {items.firstName}
                                </p>
                                {/* <p className="text-[#64748B] text-xs">
                            Хэлтэс албан тушаал
                          </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                        <HeartButton
                          isLike={items.isLike}
                          table="inbox"
                          id={items.id}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={conGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Continue</p>
              <div className="w-[25px] h-[25px] flex items-center justify-center bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {inboxData.con?.length >= 99 ? "+99" : inboxData.con?.length}
              </div>
            </div>
            <div style={conGradiant} className="w-full h-[4px] my-4" />
            {inboxData.con?.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/img/feedback/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Хүлээн авсан Фид-Форвард байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(inboxData.con).map((items) => {
                  return (
                    <div
                      key={items.id}
                      className="w-full p-4 bg-white rounded-xl mb-4 animation-wrapper"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              new
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {" "}
                          {items.hide ? (
                            <div className="flex items-center gap-2 mt-1">
                              <img
                                className="w-[25px]"
                                src="/img/incognito.svg"
                                alt="incognito"
                              />

                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  Нэрээ нууцалсан
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2 mt-1">
                              <div className="p-1 border border-[#000] bg-white rounded-full">
                                <LuUser2 className="text-sm text-[#000]" />
                              </div>
                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  {items.lastName} {items.firstName}
                                </p>
                                {/* <p className="text-[#64748B] text-xs">
                            Хэлтэс албан тушаал
                          </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                        <HeartButton
                          isLike={items.isLike}
                          table="inbox"
                          id={items.id}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={stopGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Stop</p>
              <div className="w-[25px] h-[25px] flex items-center justify-center bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {inboxData.stop?.length >= 99 ? "+99" : inboxData.stop?.length}
              </div>
            </div>
            <div style={stopGradiant} className="w-full h-[4px] my-4" />
            {inboxData.stop?.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/img/feedback/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Хүлээн авсан Фид-Форвард байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(inboxData.stop).map((items) => {
                  return (
                    <div
                      key={items.id}
                      className="w-full p-4 bg-white rounded-xl mb-4 animation-wrapper"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              new
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {" "}
                          {items.hide ? (
                            <div className="flex items-center gap-2 mt-1">
                              <img
                                className="w-[25px]"
                                src="/img/incognito.svg"
                                alt="incognito"
                              />

                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  Нэрээ нууцалсан
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2 mt-1">
                              <div className="p-1 border border-[#000] bg-white rounded-full">
                                <LuUser2 className="text-sm text-[#000]" />
                              </div>
                              <div>
                                <p className="text-[#1E293B] text-xs">
                                  {items.lastName} {items.firstName}
                                </p>
                                {/* <p className="text-[#64748B] text-xs">
                            Хэлтэс албан тушаал
                          </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                        <HeartButton
                          isLike={items.isLike}
                          table="inbox"
                          id={items.id}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopFeedback;
