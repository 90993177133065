import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";

import { LuUser2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { likeButton } from "../../../service/feedback.service";

const HeartButton = ({ isLike, table, id }) => {
  const [animationStep, setAnimationStep] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [like, setLike] = useState(isLike);
  const [delayedLike, setDelayedLike] = useState(isLike);

  const startAnimation = async () => {
    setIsButtonDisabled(true);
    setLike(!like);

    if (like) {
      setAnimationStep("slideLeft");
      const slideLeftTimeout = setTimeout(() => setAnimationStep(""), 2000);
      await likeButton(id);
      setIsButtonDisabled(false);
      setDelayedLike(!like);
      return () => {
        clearTimeout(slideLeftTimeout);
      };
    } else {
      setAnimationStep("slideIn");
      const slideInTimeout = setTimeout(() => setAnimationStep("shake"), 2000);
      const fadeOutTimeout = setTimeout(() => setAnimationStep(""), 4000);
      const likeTimeout = setTimeout(() => setDelayedLike(!like), 4000);

      await likeButton(id);

      setIsButtonDisabled(false);
      return () => {
        clearTimeout(slideInTimeout);
        clearTimeout(fadeOutTimeout);
        clearTimeout(likeTimeout);
      };
    }
  };

  return (
    <div className="">
      <button
        onClick={startAnimation}
        className="flex items-center relative z-10"
        disabled={table === "send" || isButtonDisabled}
        key={`butn_${id}_${isLike}`}
      >
        {delayedLike ? (
          <img src="/img/feedback/hearth.svg" alt="heart" />
        ) : (
          <img src="/img/feedback/hearthGray.svg" alt="heart" />
        )}
      </button>

      <div
        className={`heart-animation-container heart-animation ${
          animationStep === "slideLeft"
            ? "slideLeft-animation"
            : animationStep === "slideIn"
            ? "slideIn-animation"
            : animationStep === "shake"
            ? "shake-animation"
            : ""
        }`}
      >
        <img src="/img/feedback/hearth.svg" alt="heart" />
      </div>
    </div>
  );
};

const MobileFeedback = ({ inboxData, sendData }) => {
  const height = window.innerHeight - 240;
  const [table, setTable] = useState("CONTINUE");
  const [selected, setSelected] = useState("inbox");

  const navigate = useNavigate();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `/${year}/${month}/${day}`;
  }

  const isLatest = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();

    const timeDifference = currentDate - createdDate;
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

    return timeDifference <= threeDaysInMs;
  };

  const getItems = () => {
    if (selected === "inbox") {
      if (table === "START") return inboxData.start || [];
      if (table === "STOP") return inboxData.stop || [];
      if (table === "CONTINUE") return inboxData.con || [];
    } else if (selected === "send") {
      if (table === "START") return sendData.start || [];
      if (table === "STOP") return sendData.stop || [];
      if (table === "CONTINUE") return sendData.con || [];
    }
    return [];
  };

  const startGradiant = {
    background: "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)",
  };

  const conGradiant = {
    background: "linear-gradient(90deg, #48D7B4, #46DF9F, #01CF60)",
  };

  const stopGradiant = {
    background: "linear-gradient(90deg, #FFA354, #FF9840, #FF5C00)",
  };

  return (
    <div className="px-[20px] pt-2">
      <div className="flex justify-between items-center mb-4">
        <select
          onChange={(e) => {
            setSelected(e.target.value);
          }}
          className="text-[#324D72] text-xs px-2 py-1 border border-[#CEDAE9] rounded-md bg-[#F4F6FB]"
        >
          <option value="inbox">Хүлээн авсан</option>
          <option value="send">Илгээсэн</option>
        </select>
        <button
          onClick={() => {
            navigate("search");
          }}
          className="text-[#fff] text-sm flex items-center gap-1 bg-[#324d72] rounded-md px-3 py-2 mb-2"
        >
          <FaPlus />
          Фид-Форвард өгөх
        </button>
      </div>
      {/* <div className="mb-4"></div> */}
      <div className="flex items-center justify-between gap-2">
        <button
          onClick={() => {
            setTable("CONTINUE");
          }}
          className={` pb-1  flex items-center gap-1 ${
            table === "CONTINUE"
              ? "text-[#324d72] "
              : "text-[#666874 border-none "
          }`}
        >
          <div
            style={table === "CONTINUE" ? conGradiant : {}}
            className="rounded-full w-[6px] h-[6px]"
          ></div>
          Continue
          <div className="w-[20px] h-[20px] bg-[#E0E0E0] text-[#625F6D] text-xs flex items-center justify-center rounded-full">
            {selected === "inbox"
              ? inboxData.con.length > 99
                ? "+99"
                : inboxData.con.length
              : sendData.con.length > 99
              ? "+99"
              : sendData.con.length}
          </div>
        </button>
        <button
          onClick={() => {
            setTable("START");
          }}
          className={` pb-1  flex items-center gap-1 ${
            table === "START" ? "text-[#324d72] " : "text-[#666874 border-none "
          }`}
        >
          <div
            style={table === "START" ? startGradiant : {}}
            className="rounded-full w-[6px] h-[6px]"
          ></div>
          Start
          <div className="w-[20px] h-[20px] bg-[#E0E0E0] text-[#625F6D] text-xs flex items-center justify-center rounded-full">
            {selected === "inbox"
              ? inboxData.start.length > 99
                ? "+99"
                : inboxData.start.length
              : sendData.start.length > 99
              ? "+99"
              : sendData.start.length}
          </div>
        </button>

        <button
          onClick={() => {
            setTable("STOP");
          }}
          className={` pb-1  flex items-center gap-1 ${
            table === "STOP" ? "text-[#324d72] " : "text-[#666874 border-none "
          }`}
        >
          <div
            style={table === "STOP" ? stopGradiant : {}}
            className="rounded-full w-[6px] h-[6px]"
          ></div>
          Stop{" "}
          <div className="w-[20px] h-[20px] bg-[#E0E0E0] text-[#625F6D] text-xs flex items-center justify-center rounded-full">
            {selected === "inbox"
              ? inboxData.stop.length > 99
                ? "+99"
                : inboxData.stop.length
              : sendData.stop.length > 99
              ? "+99"
              : sendData.stop.length}
          </div>
        </button>
      </div>
      <hr
        style={
          table === "START"
            ? startGradiant
            : table === "CONTINUE"
            ? conGradiant
            : stopGradiant
        }
        className="my-2 h-[3px]"
      />

      <div
        style={{ height: `${height}px` }}
        className="mt-4 p-4 overflow-y-scroll"
      >
        {getItems().length === 0 ? (
          <div className="flex flex-col items-center justify-center ">
            <img src="/img/feedback/feedback.svg" alt="nothing" />
            <p className="text-[#1E293B] text-sm">
              Илгээсэн feed-forward байхгүй
            </p>
          </div>
        ) : (
          getItems().map((items, index) => (
            <div
              key={index}
              className="w-full p-4 bg-white rounded-xl mb-4 shadow-custom animation-wrapper"
            >
              <div className="flex items-center justify-between">
                <p className="text-[#64748B] text-xs py-0.5">
                  {formatDate(items.createdDate)}
                </p>
                {isLatest(items.createdDate) && (
                  <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED]">
                    <p className="text-[#FF9D72] font-semibold text-xs">new</p>
                  </div>
                )}
              </div>
              <div>
                <p className="text-xs text-[#222] py-2">{items.text}</p>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  {items.hide ? (
                    <div className="flex items-center gap-2 mt-1">
                      <img
                        className="w-[25px]"
                        src="/img/incognito.svg"
                        alt="incognito"
                      />

                      <div>
                        <p className="text-[#1E293B] text-xs">
                          Нэрээ нууцалсан
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center gap-2 mt-1">
                      <div className="p-1 border border-[#000] bg-white rounded-full">
                        <LuUser2 className="text-sm text-[#000]" />
                      </div>
                      <div>
                        <p className="text-[#1E293B] text-xs">
                          {items.lastName} {items.firstName}
                        </p>
                        {/* <p className="text-[#64748B] text-xs">
                            Хэлтэс албан тушаал
                          </p> */}
                      </div>
                    </div>
                  )}
                </div>
                <HeartButton
                  isLike={items.isLike}
                  key={index + items.id}
                  table={selected}
                  id={items.id}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MobileFeedback;
