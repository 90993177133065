import React, { useContext, useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { SidebarContext } from "../context/SidebarContext";
import menu from "../json/menu.json";

import { NightModeContext } from "../context/NightModeContext";
import { UserContext } from "../context/userContext";

const Sidebar = () => {
  const imgRef = useRef(null);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const { sidebar } = useContext(SidebarContext);
  const { user } = useContext(UserContext);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const { nightMode } = useContext(NightModeContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const imgElement = imgRef.current;
      if (imgElement) {
        const handleImageLoad = () => {
          const imgWidth = imgElement.naturalWidth;
          const imgHeight = imgElement.naturalHeight;
          if (imgWidth > imgHeight) {
            if (imgWidth > 175) {
              setImgSize({ width: 175, height: "auto" });
            } else {
              if (imgHeight > 100) {
                setImgSize({ width: "auto", height: 100 });
              } else {
                setImgSize({ width: imgWidth, height: imgHeight });
              }
            }
          } else {
            if (imgHeight > 100) {
              setImgSize({ width: "auto", height: 100 });
            } else {
              setImgSize({ width: imgWidth, height: imgHeight });
            }
          }
        };

        imgElement.addEventListener("load", handleImageLoad);

        return () => {
          imgElement.removeEventListener("load", handleImageLoad);
        };
      }
    }
  }, [user, sidebar]);

  const handleMenuClick = (index) => {
    setSelectedMenu(index);
  };

  return (
    <div>
      {sidebar === "open" ? (
        <div
          className={`min-h-screen border-r w-[280px] p-4 ${
            nightMode
              ? "bg-[#372763] text-white border-[#391f80]"
              : "bg-[#FFFFFF] text-black border-[#E2E8F0]"
          }  px-6 py-4`}
        >
          <div className="flex items-center justify-center gap-3 mt-8">
            <Link to={"/"} className=" flex justify-center gap-4 p-4">
              {user !== null ? (
                <img
                  ref={imgRef}
                  src={user.companyPhoto}
                  alt="logo"
                  style={{ width: imgSize.width, height: imgSize.height }}
                />
              ) : (
                <img width={"175px"} src="./oneplacehrLogo.png " alt="logo" />
              )}
            </Link>
            {/* <button
              onClick={() => {
                navigate("/profile");
              }}
              className="ms-3"
            >
              <CiSettings
                className={`${
                  !nightMode ? "text-[#2C4360]" : "text-[#fff]"
                } text-2xl`}
              />
            </button> */}
          </div>

          <div className="mt-8">
            {menu.map((menu) => {
              if (menu.id === 1 || menu.id === 2 || menu.id === 4) {
                return (
                  <button
                    onClick={() => {
                      handleMenuClick(menu.id);
                      navigate(menu.link);
                    }}
                    key={menu.id}
                    className={`px-3 py-3 text-sm w-full rounded-xl flex  gap-2 font-semibold mb-2 ${
                      selectedMenu === menu.id
                        ? nightMode
                          ? "bg-[#5A4D8A] text-[#F4F6FB]"
                          : "bg-[#324D72] text-[#fff]"
                        : nightMode
                        ? "hover:bg-[#4B3B6A] text-[#D1D5DB]"
                        : "hover:bg-[#f6f6f6] text-[#666874]"
                    }`}
                  >
                    <span className="">
                      <img
                        className="w-[20px] text-[#666874]"
                        src={
                          menu.id === selectedMenu || nightMode
                            ? menu.selectedImg
                            : menu.img
                        }
                        alt="sidebar"
                      />
                    </span>{" "}
                    {menu.name}
                    {/* {selectedMenu !== menu.id ? (
                              <div className="text-[#fff] bg-[#FD6B3A] rounded-xl px-[5px] flex items-center justify-center text-xs ">
                                1
                              </div>
                            ) : (
                              ""
                            )} */}
                  </button>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sidebar;
