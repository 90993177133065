import api from "../helper/request";

const login = (obj) => api.post("/employees/login", obj);

const getUserData = () => api.get("/employees/info");

const loginById = (id) => api.post(`/employees/login/empId?empId=${id}`);

const getNotf = () => api.get(`/employees/assign/detail`);

const editUser = (obj) => api.post(`/employees/update`, obj);
export { login, getUserData, loginById, getNotf, editUser };
