import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loginById } from "../service/user.service";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

const LoginById = () => {
  const navigate = useNavigate();
  const { word, id } = useParams();

  useEffect(() => {
    if (id) {
      loginById(id)
        .then((res) => {
          if (res.data.accessToken) {
            const token = res.data.accessToken;

            // Save token in cookies
            Cookies.set("metacog_token", token, { expires: 7 });

            if (res.data.onboardSize === 0) {
              navigate({ pathname: "/feed-forward" });
            } else {
              navigate({ pathname: "/onboard" });
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
          toast.error("Invalid ID");
        });
    }
  }, [id, navigate]);

  return (
    <div className="h-screen w-full m-auto flex items-center justify-center">
      <p>Loading...</p>
      <ToastContainer />
    </div>
  );
};

export default LoginById;
