import React, { useContext, useEffect, useState } from "react";
import { TitleContext } from "../context/TitleContext";
import Skeleton from "react-loading-skeleton";
import { getNotf } from "../service/user.service";

const Notifications = () => {
  const [hover, setHover] = useState(0);
  const { setTitle } = useContext(TitleContext);
  const height = window.innerHeight - 150;
  const [table, setTable] = useState("active");

  const [notfData, setNotfData] = useState(null);
  const [unNotfData, setUnNotfData] = useState(null);

  useEffect(() => {
    setTitle("Notifications");
  }, [setTitle]);

  useEffect(() => {
    getNotf().then((data) => {
      const allData = [
        ...data.data.surveys.map((item) => ({ ...item, type: "Survey" })),
        ...data.data.burnouts.map((item) => ({ ...item, type: "Burnout" })),
        ...data.data.onas.map((item) => ({ ...item, type: "Ona" })),
      ];

      const finished = allData.filter((item) => item.isFinished);
      const unfinished = allData.filter((item) => !item.isFinished);

      const sortByDate = (a, b) => {
        if (!a.createdDate) return 1;
        if (!b.createdDate) return -1;
        return new Date(b.createdDate) - new Date(a.createdDate);
      };

      finished.sort(sortByDate);
      unfinished.sort(sortByDate);

      const sortedActive = [...unfinished].map((item) => ({
        ...item,
        timeAgo: item.createdDate ? findWhen(item.createdDate) : "No date",
      }));
      const sortedUnActive = [...finished].map((item) => ({
        ...item,
        timeAgo: item.createdDate ? findWhen(item.createdDate) : "No date",
      }));

      setNotfData(sortedActive);
      setUnNotfData(sortedUnActive);
    });
  }, []);

  const findWhen = (createdDate) => {
    const now = new Date();
    const created = new Date(createdDate);
    const diffInMs = now - created;

    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInMonths >= 1) {
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    } else if (diffInDays >= 1) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <div className="w-10/12 m-auto pt-11">
      <div className="flex items-center mb-4">
        <button
          onClick={() => setTable("active")}
          className={`w-[50%] text-xs font-semibold ${
            table === "active"
              ? "text-[#324D72] border-b-2 border-[#324D72]"
              : "text-[#666874]"
          }`}
        >
          Идэвхитэй [{notfData ? notfData.length : 0}]
        </button>
        <button
          onClick={() => setTable("unactive")}
          className={`w-[50%] text-xs font-semibold ${
            table === "unactive"
              ? "text-[#324D72] border-b-2 border-[#324D72]"
              : "text-[#666874]"
          }`}
        >
          Өмнөх [{unNotfData ? unNotfData.length : 0}]
        </button>
      </div>
      <div
        style={{ maxHeight: `${height}px` }}
        className="overflow-y-scroll pb-10"
      >
        {notfData === null || unNotfData === null ? (
          <div className="w-full">
            {[...Array(6)].map((_, index) => (
              <div key={index} className="flex items-center mb-3">
                <Skeleton circle={true} height={40} width={40} />
                <div className="ml-2">
                  <Skeleton width={200} />
                  <Skeleton width={150} />
                </div>
              </div>
            ))}
          </div>
        ) : notfData.length === 0 && unNotfData.length === 0 ? (
          <div className="w-full mt-20 flex items-center justify-center  ">
            <div>
              <div className="flex justify-center">
                <img src="/img/notf/empty.svg" alt="empty" />
              </div>{" "}
              <p className="text-center text-[#1E293B] text-sm">
                Одоогоор мэдэгдэл ирээгүй байна.
              </p>
            </div>
          </div>
        ) : table === "active" && notfData.length === 0 ? (
          <div className="w-full mt-20 flex items-center justify-center  ">
            <div>
              <div className="flex justify-center">
                <img src="/img/notf/empty.svg" alt="empty" />
              </div>{" "}
              <p className="text-center text-[#1E293B] text-sm">
                Одоогоор идэвхтэй мэдэгдэл ирээгүй байна.
              </p>
            </div>
          </div>
        ) : table === "unactive" && unNotfData.length === 0 ? (
          <div className="w-full mt-20 flex items-center justify-center  ">
            <div>
              <div className="flex justify-center">
                <img src="/img/notf/empty.svg" alt="empty" />
              </div>{" "}
              <p className="text-center text-[#1E293B] text-sm">
                Одоогоор идэвхгүй мэдэгдэл ирээгүй байна.
              </p>
            </div>
          </div>
        ) : (
          <div className="w-full">
            {table === "active"
              ? notfData.map((items, index) => {
                  return (
                    <div
                      key={index + 1}
                      onMouseEnter={() => {
                        setHover(index + 1);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        window.open(items.url, "_blank");
                      }}
                      className={`py-3 px-2  flex items-center gap-2 w-full h-[75px] cursor-pointer relative ${
                        hover === index + 1
                          ? "bg-[#cfcfcf] bg-opacity-30"
                          : "bg-none"
                      }`}
                    >
                      <div className="h-[40px] w-[10%] flex items-center justify-center">
                        <div className="w-[28px] h-[28px] flex items-center justify-center rounded-full bg-[#CDE8FF]">
                          <img
                            src={`/img/notf/${
                              items.type === "Feedback"
                                ? "feedback.svg"
                                : "notf.svg"
                            }`}
                            alt="surv"
                          />
                        </div>
                      </div>
                      <div className="w-[90%] overflow-hidden">
                        <div className="flex items-center justify-between gap-2">
                          <p
                            className={`text-xs overflow-hidden text-[#1E293B]`}
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              lineHeight: "1.2em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {items.name}
                          </p>
                          <p className={`text-[#777985] text-[10px]`}>
                            {items.timeAgo}
                          </p>
                        </div>
                        <p className={`m-0 text-[10px] text-[#777985]`}>
                          {items.type === "Feedback "
                            ? "Танд шинэ feed-forward ирсэн байна."
                            : items.type === "Survey"
                            ? "Танд Pulse survey ирсэн байна."
                            : items.type === "Ona"
                            ? "Танд ONA асуумж ирсэн байна."
                            : items.type === "Burnout"
                            ? "Танд ONA асуумж ирсэн байна."
                            : "Танд Onboarding ирсэн байна."}
                        </p>
                      </div>
                      {!items.isFinished && (
                        <div className="h-[8px] w-[8px] rounded-full bg-[#324D72] absolute top-10 right-2 -translate-y-1/2" />
                      )}
                    </div>
                  );
                })
              : unNotfData.map((items, index) => {
                  return (
                    <div
                      key={index + 1}
                      onMouseEnter={() => {
                        setHover(index + 1);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      onClick={() => {
                        window.open(items.url, "_blank");
                      }}
                      className={`py-3 px-2  flex items-center gap-2 w-full h-[75px] cursor-pointer relative ${
                        hover === index + 1
                          ? "bg-[#cfcfcf] bg-opacity-30"
                          : "bg-none"
                      }`}
                    >
                      <div className="h-[40px] w-[10%] flex items-center justify-center">
                        <div className="w-[28px] h-[28px] flex items-center justify-center rounded-full bg-[#CDE8FF]">
                          <img
                            src={`/img/notf/${
                              items.type === "Feedback"
                                ? "feedback.svg"
                                : "notf.svg"
                            }`}
                            alt="surv"
                          />
                        </div>
                      </div>
                      <div className="w-[90%] overflow-hidden">
                        <div className="flex items-center justify-between gap-2">
                          <p
                            className={`text-xs overflow-hidden text-[#1E293B]`}
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              lineHeight: "1.2em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {items.name}
                          </p>
                          <p className={`text-[#777985] text-[10px]`}>
                            {items.timeAgo}
                          </p>
                        </div>
                        <p className={`m-0 text-[10px] text-[#777985]`}>
                          {items.type === "Feedback "
                            ? "Танд шинэ feed-forward ирсэн байна."
                            : items.type === "Survey"
                            ? "Танд Pulse survey ирсэн байна."
                            : items.type === "Ona"
                            ? "Танд ONA асуумж ирсэн байна."
                            : items.type === "Burnout"
                            ? "Танд ONA асуумж ирсэн байна."
                            : "Танд Onboarding ирсэн байна."}
                        </p>
                      </div>
                      {!items.isFinished && (
                        <div className="h-[8px] w-[8px] rounded-full bg-[#324D72] absolute top-10 right-2 -translate-y-1/2" />
                      )}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
