import api from "../helper/request";

const createFeedback = (obj) => api.post(`/feedback-answer/create`, obj);

const getFeedback = () => api.get(`/feedback-answer/own`);

const getSendedFeedback = () => api.get(`/feedback-answer/send-history`);

const getDep = () => api.get("/employees/department");

const getEmp = () => api.get("/employees/list");

const likeButton = (id) => api.post(`/feedback-answer/like?id=${id}`);

export {
  createFeedback,
  getFeedback,
  getDep,
  getEmp,
  getSendedFeedback,
  likeButton,
};
