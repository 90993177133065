import React, { useEffect, useState } from "react";
import MobileFeedbackEmp from "../../components/Feedback/mobile/MobileFeedbackEmp";
import DesktopFeedbackEmp from "../../components/Feedback/desktop/DesktopFeedbackEmp";
import { getDep, getEmp } from "../../service/feedback.service";
import { useParams } from "react-router-dom";

const FeedbackEmp = () => {
  const [employees, setEmployees] = useState(null);
  const { id } = useParams();
  const [empData, setEmpData] = useState(null);

  useEffect(() => {
    getEmp()
      .then((data) => setEmployees(data.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (id && employees) {
      const founded = employees.find((item) => item.id === Number(id));
      setEmpData(founded);
    }
  }, [id, employees]);

  if (empData === null) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        Loading...
      </div>
    );
  }

  return (
    <div className="md:w-11/12 sm:w-full w-full md:pt-6 pt-0 m-auto">
      <div className="hidden sm:hidden md:block">
        <DesktopFeedbackEmp EmpData={empData} />
      </div>
      <div className="block sm:block md:hidden">
        <MobileFeedbackEmp EmpData={empData} />
      </div>
    </div>
  );
};

export default FeedbackEmp;
