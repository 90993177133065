import React, { useEffect, useState } from "react";
import DesktopFeedbackSearch from "../../components/Feedback/desktop/DesktopFeedbackSearch";
import MobileFeedbackSearch from "../../components/Feedback/mobile/MobileFeedbackSearch";
import { getDep, getEmp } from "../../service/feedback.service";

const FeedbackSearch = () => {
  const [emp, setEmp] = useState(null);
  const [dep, setDep] = useState(null);

  useEffect(() => {
    getEmp()
      .then((data) => {
        setEmp(data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    getDep()
      .then((data) => {
        setDep(data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  if (emp === null || dep === null) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        Loading...
      </div>
    );
  }
  return (
    <div className="md:w-11/12 sm:w-10/12 w-10/12 pt-11 m-auto">
      <div className="hidden sm:hidden md:block">
        <DesktopFeedbackSearch employee={emp} department={dep} />
      </div>
      <div className="block sm:block md:hidden">
        <MobileFeedbackSearch employee={emp} department={dep} />
      </div>
    </div>
  );
};

export default FeedbackSearch;
